import { createRouter, createWebHistory } from "vue-router";
import Auth from "@/middlewares/auth";

import Home from "@/views/Home.vue";
import Coupons from "@/views/Coupons.vue";
import Transactions from "@/views/Transactions/Index.vue";
import SingleTransaction from "@/views/Transactions/_id.vue";
import Shops from "@/views/Shops/Index.vue";
import ShopsAll from "@/views/Shops/_id/Index.vue";
import ShopDetails from "@/views/Shops/_id/_shopId.vue";
import LogIn from "@/views/auth/LogIn";
import SignUp from "@/views/auth/SignUp";
import ForgotPassword from "@/views/auth/ForgotPassword";

const AUTH = new Auth();

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/transactions",
    name: "Transactions",
    component: Transactions,
  },
  {
    path: "/transactions/:id",
    name: "TransactionsId",
    component: SingleTransaction,
  },
  {
    path: "/coupons",
    name: "Coupons",
    component: Coupons,
  },
  {
    path: "/shops",
    name: "Shops",
    component: Shops,
  },
  {
    path: "/shops/:id",
    name: "ShopsId",
    component: ShopsAll,
  },
  {
    path: "/shops/:id/:shopId",
    name: "ShopIdStore",
    component: ShopDetails,
  },
  {
    path: "/log-in",
    name: "login",
    component: LogIn,
    meta: {
      layout: "auth",
      noAuth: true,
    },
  },
  {
    path: "/sign-up",
    name: "signup",
    component: SignUp,
    meta: {
      layout: "auth",
      noAuth: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      layout: "auth",
      noAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.noAuth || false) {
    // Show current page since auth is not required.
    next();
  } else {
    // Run through authenticated methods.
    AUTH.authenticate()
      .then(() => {
        next();
      })
      .catch(() => {
        next({
          path: "/log-in",
        });
      });
  }
});

export default router;
