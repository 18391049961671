<template>
  <div class="skeleton-loader">
    <template v-if="type === 'graph'">
      <div class="skeleton-loader__graph">
        <div
          v-for="(n, index) in 12"
          :key="n"
          :class="`bar bar-${index} gradient`"
        ></div>
      </div>
    </template>
    <template v-else-if="type === 'card'">
      <div class="skeleton-loader__card gradient" />
    </template>
    <template v-else-if="type === 'table'">
      <div class="table-overflow">
        <table class="skeleton-loader__table" cellpadding="0" cellspacing="0">
          <thead>
            <tr>
              <th v-for="n in 4" :key="n">
                <div class="title gradient"></div>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in 10" :key="row">
              <td v-for="column in 4" :key="column">
                <div class="text gradient"></div>
              </td>
              <td>
                <svg
                  width="8"
                  height="14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m1 13 6-6-6-6"
                    stroke="#E0C097"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-else-if="type === 'shop-details'">
      <div class="skeleton-loader__shop">
        <div class="image gradient" />
        <div class="name gradient" />
        <div class="address gradient" />
        <div class="phone">
          <svg
            width="18"
            height="20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.603 8.021c1.45-1.132 3.228-1.468 2.714-3.427-.513-1.96-1.582-6.48-4.535-3.73-8.372 7.8 3.968 24.286 13.04 17.558 3.221-2.385-.676-4.551-2.352-5.543-1.679-.992-2.407.758-3.789 1.984C7.963 16.383 2.8 9.43 4.603 8.021Z"
              fill="#B85C38"
            />
          </svg>
          <div class="number gradient" />
        </div>
        <div class="divider" />
        <div class="info">
          <div>
            <div class="hours">
              <div class="title gradient" />
              <svg
                width="14"
                height="14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12.6 7A5.6 5.6 0 1 1 1.4 7a5.6 5.6 0 0 1 11.2 0ZM14 7A7 7 0 1 1 0 7a7 7 0 0 1 14 0ZM7.7 4.2a.7.7 0 1 0-1.4 0V7a.7.7 0 0 0 .205.495l2.1 2.1a.7.7 0 0 0 .99-.99L7.7 6.71V4.2Z"
                  fill="#000"
                />
              </svg>
            </div>
            <ul>
              <li v-for="day in 7" :key="day">
                <div class="day gradient" />
                <div class="day gradient" />
              </li>
            </ul>
          </div>
          <div class="map gradient"></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.gradient {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #eaeaea;
  background: linear-gradient(to right, #eaeaea 8%, #d5d5d5 38%, #eaeaea 54%);
  background-size: 1000px 640px;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.skeleton-loader {
  &__graph {
    box-sizing: border-box;
    position: relative;
    height: 400px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 24px;
    padding-left: 50px;
    padding-right: 20px;
    padding-bottom: 20px;
    @media (max-width: 768px) {
      grid-template-columns: repeat(6, 1fr);
      @media (max-width: 450px) {
        padding-left: 40px;
        grid-gap: 12px;
      }
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 20px;
      border-radius: 20px 20px 0 0;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background: #cccccc;
      background: linear-gradient(
        to right,
        #cccccc 8%,
        #ffffff 38%,
        #cccccc 54%
      );
      background-size: 1000px 640px;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: black;
      height: 20px;
      width: 100%;
      border-radius: 0 20px 20px 0;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
      background: #cccccc;
      background: linear-gradient(
        to right,
        #cccccc 8%,
        #ffffff 38%,
        #cccccc 54%
      );
      background-size: 1000px 640px;
    }
    .bar {
      margin-top: auto;
      width: 100%;
      background-color: #eaeaea;
      border-radius: 8px 8px 0 0;
      &-0,
      &-4,
      &-8 {
        height: 80%;
      }
      &-1,
      &-5,
      &-9 {
        height: 60%;
      }
      &-2,
      &-6,
      &-10 {
        height: 75%;
      }
      &-3,
      &-7,
      &-11 {
        height: 90%;
      }
      &-6,
      &-7,
      &-8,
      &-9,
      &-10,
      &-11 {
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
  &__card {
    height: 216px;
    border-radius: 12px;
    @media (max-width: 991px) {
      height: 195px;
    }
  }
  .table-overflow {
    overflow-x: auto;
    overflow-y: hidden;
  }
  &__table {
    width: 100%;
    min-width: 800px;
    .title {
      height: 20px;
      width: 100%;
      max-width: 50px;
      min-width: 50px;
    }
    .text {
      width: 100%;
      height: 20px;
      max-width: 150px;
    }
    tr {
      th {
        padding: 15px 0;
        border-bottom: 1px solid rgba(224, 192, 151, 0.5);
        &:nth-last-child(-n + 3) {
          .title,
          .text {
            margin-left: auto;
          }
        }
      }
      td {
        padding: 20px 0;
        border-bottom: 1px solid rgba(224, 192, 151, 0.5);
        &:nth-last-child(-n + 3) {
          .title,
          .text {
            margin-left: auto;
          }
        }
        &:last-child {
          text-align: center;
          width: 70px;
        }
      }
    }
  }
  &__shop {
    .image {
      width: 100%;
      max-width: 285px;
      min-height: 100px;
      max-height: 100px;
      margin-bottom: 32px;
    }
    .name {
      width: 100%;
      max-width: 400px;
      height: 42px;
      margin-bottom: 9px;
    }
    .address {
      width: 100%;
      max-width: 300px;
      height: 28px;
      margin-bottom: 41px;
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
    .phone {
      display: flex;
      align-items: center;
      svg {
        display: block;
        margin-right: 16px;
        @media (max-width: 768px) {
          margin-right: 10px;
        }
      }
      .number {
        width: 100%;
        max-width: 200px;
        height: 22px;
      }
    }
    .divider {
      margin: 40px 0 80px 0;
      width: 100%;
      height: 1px;
      background-color: $calico;
      opacity: 0.5;
      @media (max-width: 768px) {
        margin: 25px 0;
      }
    }
    .info {
      display: grid;
      grid-template-columns: 5fr 7fr;
      grid-gap: 60px;
      @media (max-width: 768px) {
        grid-template-columns: 12fr;
        grid-gap: 30px;
      }
      .hours {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .title {
          width: 100%;
          max-width: 220px;
          height: 28px;
        }
        svg {
          display: block;
          margin-left: 10px;
        }
      }
      ul {
        padding: 0;
        margin: 19px 0 0 0;
        list-style: none;
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px 5px;
          border-bottom: 1px solid rgba(224, 192, 151, 0.5);
          .day {
            width: 100%;
            max-width: 100px;
            height: 17px;
          }
        }
      }
      .map {
        width: 100%;
        height: 100%;
        min-width: 350px;
      }
    }
  }
}
</style>
