<template>
  <div :ref="`alert${id}`" class="alert" :class="[`alert-${type}`]">
    <span v-if="!iconHidden" class="svg-icon">
      <VueFeather :type="icon" :class="[`text-${type}`]" />
    </span>

    <div class="flex">
      <h4 v-if="title" :class="[`text-${type}`]">{{ title }}</h4>
      <span v-if="content" class="content">{{ content }}</span>
    </div>

    <span v-if="closeable" @click="remove" class="svg-icon">
      <VueFeather type="x" :class="[`text-${type}`]" />
    </span>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },

    content: {
      type: String,
      required: false,
      default: null,
    },

    iconHidden: {
      type: Boolean,
      required: false,
      default: false,
    },

    icon: {
      type: String,
      required: false,
      default: "alert-circle",
    },

    type: {
      type: String,
      required: false,
      default: "danger",
    },

    closeable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    id: uuidv4(),
  }),

  methods: {
    remove() {
      this.$refs[`alert${this.id}`].remove();
    },
  },
};
</script>

<style lang="scss" scoped>
.alert {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 16px;
  border: 1px solid transparent;
  border-radius: 15px;
  &-danger {
    color: #903c29;
    background-color: #fce0da;
    border-color: #fbd1c7;
  }
  .content {
    font-size: 13px;
  }
  .svg-icon {
    margin-left: 24px;
    cursor: pointer;
    i {
      display: block;
      &.text-danger {
        color: #f06445;
      }
    }
  }
}
</style>
