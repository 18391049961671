<template>
  <div class="container__inner-right">
    <div class="signin__box">
      <p class="register">
        {{ $t("labels.notAMember") }}
        <router-link class="link" to="/sign-up">
          {{ $t("labels.signUp") }}
        </router-link>
      </p>
      <h3>{{ $t("labels.forgotPassword") }}?</h3>
      <p>
        {{ $t("labels.instructions") }}
      </p>
      <Input
        v-model="email"
        label="Email"
        name="email"
        type="text"
        :placeholder="$t('labels.writeYourEmail')"
        :rules="['required', 'email']"
      />

      <div class="buttons">
        <button>{{ $t("labels.sendResetInstructions") }}</button>
        <router-link class="link" to="/log-in"
          >{{ $t("labels.back") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.signin__box {
  margin: 0 auto;
  padding: 63px 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 500px;
  @media (max-width: 768px) {
    padding: 30px 20px;
  }
  .register {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $white;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 120px;
    @media (max-width: 768px) {
      margin-bottom: 60px;
    }
    .link {
      margin-left: 10px;
      text-decoration: none;
      color: $calico;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }
  }
  h3 {
    font-size: 36px;
    font-weight: 800;
    line-height: 50px;
    color: $white;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 42px;
      margin-bottom: 30px;
    }
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 80px;
    color: $white;
    @media (max-width: 768px) {
      margin-bottom: 40px;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 400px) {
      display: block;
    }
    button {
      cursor: pointer;
      background-color: $calico;
      padding: 24px 40px;
      box-shadow: 0px 5px 15px #2d2424;
      border-radius: 16px;
      border: none;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      margin: 60px 0px 0 0;
      @media (max-width: 1024px) {
        margin: 0 0 30px 0;
        padding: 15px 20px;
        font-size: 16px;
        @media (max-width: 400px) {
          width: 100%;
        }
      }
    }
    .link {
      cursor: pointer;
      display: block;
      text-decoration: none;
      color: $calico;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin: 60px 0px 0 0;
      @media (max-width: 1024px) {
        margin: 0 0 30px 0;
        padding: 15px 20px;
        font-size: 16px;
        @media (max-width: 400px) {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  .link {
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: $calico;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    transition: 0.3s;
    &:hover {
      color: $white;
    }
  }
}
</style>
