<template>
  <div class="container__inner-right">
    <div class="signin__box">
      <p class="register">
        {{ $t("labels.alreadyAMember") }}
        <router-link class="link" to="/log-in">{{
          $t("labels.signInCupon")
        }}</router-link>
      </p>
      <h3>{{ $t("labels.signUpCupon") }}</h3>
      <p class="paragraph">
        {{ $t("labels.download") }}
      </p>
      <div class="image__box">
        <img src="@/assets/img/appstore.png" alt="appstore" />
        <img src="@/assets/img/googleplay.png" alt="googleplay" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.signin__box {
  margin: 0 auto;
  padding: 63px 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 500px;
  @media (max-width: 768px) {
    padding: 30px 20px;
  }
  .register {
    text-align: right;
    color: $white;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 120px;
    @media (max-width: 768px) {
      margin-bottom: 60px;
    }
    .link {
      margin-left: 10px;
      text-decoration: none;
      color: $calico;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }
  }
  h3 {
    font-size: 36px;
    font-weight: 800;
    line-height: 50px;
    color: $white;
    margin-bottom: 60px;
    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 42px;
      margin-bottom: 30px;
    }
  }
  .paragraph {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 30px 0 50px 0;
    color: $white;
  }
  .image__box {
    display: flex;
    align-items: center;
    @media (max-width: 950px) {
      flex-direction: column;
    }
    img {
      width: 100%;
      cursor: pointer;
      max-height: 62px;
      object-fit: contain;
      @media (max-width: 950px) {
        max-height: initial;
        max-width: 205px;
      }
      &:not(:last-child) {
        margin-right: 40px;
        @media (max-width: 950px) {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
