<template>
  <div class="container__inner-right">
    <div v-if="loading_modal" class="static-loading">
      <div class="loader"></div>
    </div>

    <div class="signin__box">
      <p class="register">
        {{ $t("labels.notAMember") }}
        <router-link class="link" to="/sign-up">
          {{ $t("labels.signUp") }}
        </router-link>
      </p>
      <h3>{{ $t("labels.signInToCupon") }}</h3>
      <template v-if="errors.length > 0">
        <Alert
          v-for="(error, index) in errors"
          :key="index"
          :content="error.value[0]"
          closeable
          icon-hidden
        />
      </template>
      <form @submit.prevent="handleLogin">
        <Input
          v-model="email"
          label="Email"
          name="email"
          type="text"
          :placeholder="$t('labels.writeYourEmail')"
          :rules="['required', 'email']"
          :error="server_error.email"
        />

        <Input
          v-model="password"
          :label="$t('labels.password')"
          name="password"
          type="password"
          placeholder="********"
          :rules="['required']"
          :error="server_error.password"
        />

        <div class="buttons">
          <button type="submit" :disable="processing">
            <template v-if="!processing">{{ $t("labels.signIn") }}</template>
            <template v-else>{{ $t("labels.holdOn") }}</template>
          </button>
          <router-link class="link" to="/forgot-password"
            >{{ $t("labels.forgotPassword") }}
          </router-link>
        </div>
      </form>

      <div class="row">
        <div class="column">
          <v-facebook-login
            :app-id="$facebookAppId"
            v-model="model"
            @login="fblogin"
            @sdk-init="handleSdkInit"
          >
            <template v-slot:login>
              {{ $t("labels.facebook_login") }}
            </template>
            <template v-slot:logout>
              {{ $t("labels.facebook_login") }}
            </template>
            <template v-slot:working>
              {{ $t("labels.facebook_login") }}
            </template>
          </v-facebook-login>
        </div>
        <div class="column">
          <button
            @click="handleClickSignIn"
            :disabled="!Vue3GoogleOauth.isInit"
            class="googleLogin"
          >
            <img src="@/assets/img/google.png" alt="appstore" />
            {{ $t("labels.google_login") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import VFacebookLogin from "vue-facebook-login-component-next";
import { inject, toRefs } from "vue";

export default {
  components: {
    VFacebookLogin,
  },

  data() {
    return {
      v$: useValidate(),
      email: "",
      password: "",
      processing: false,
      errors: [],
      FB: {},
      model: {},
      scope: {},
      server_error: {
        email: null,
        password: null,
      },
      loading_modal: false,
    };
  },

  methods: {
    initLoadingModal() {
      this.loading_modal = true;
    },

    async handleClickSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        const auth = this.$gAuth.instance.currentUser.get().getAuthResponse();
        this.socialLogin(auth.access_token, "google");
      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },
    async handleClickSignOut() {
      try {
        await this.$gAuth.signOut();
      } catch (error) {
        console.error(error);
      }
    },

    handleSdkInit({ FB, scope }) {
      this.FB = FB;
      this.scope = scope;

      FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          // The user is logged in and has authenticated your
          // app, and response.authResponse supplies
          // the user's ID, a valid access token, a signed
          // request, and the time the access token
          // and signed request each expire.
          FB.logout(function (response) {
            // facebook logged out
            response;
          });
        } else if (response.status === "not_authorized") {
          // The user hasn't authorized your application.  They
          // must click the Login button, or you must call FB.login
          // in response to a user gesture, to launch a login dialog.
        } else {
          // The user isn't logged in to Facebook. You can launch a
          // login dialog with a user gesture, but the user may have
          // to log in to Facebook before authorizing your application.
        }
      });
    },
    fblogin(event) {
      // from: https://github.com/adi518/vue-facebook-login
      if (event) {
        this.socialLogin(event.authResponse.accessToken, "facebook");
      }
    },

    socialLogin(token, provider) {
      if (token) {
        const formData = new FormData();
        formData.append("provider", provider);
        formData.append("access_token", token);

        this.$auth
          .handleFbLogin(formData)
          .then(() => {
            this.initLoadingModal();
            this.$router.push("/");
          })
          .catch((error) => {
            this.errors = this.$res.validationErrors(error);
          })
          .finally(() => {
            this.processing = false;
          });
      }
    },

    handleLogin() {
      this.errors = [];
      this.server_error.email = null;
      this.server_error.password = null;
      this.v$.$validate();

      if (this.v$.$error) {
        return;
      }

      this.processing = true;

      const formData = new FormData();
      formData.append("email", this.email);
      formData.append("password", this.password);

      this.$auth
        .handleLogin(formData)
        .then(() => {
          this.initLoadingModal();
          this.$router.push("/");
        })
        .catch((error) => {
          // this.errors = this.$res.validationErrors(error);

          // Set validation errors on inputs.
          if (error.status === 422) {
            for (const property in error.data.errors) {
              const pr = property === "server" ? "password" : property;
              this.server_error[pr] = error.data.errors[property][0];
            }
          }
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },

  setup(props) {
    const { isSignIn } = toRefs(props);
    const Vue3GoogleOauth = inject("Vue3GoogleOauth");

    const handleClickLogin = () => {};
    return {
      Vue3GoogleOauth,
      handleClickLogin,
      isSignIn,
    };
  },

  mounted() {
    // this.Vue3GoogleOauth.isAuthorized.listen(this.handleClickSignIn());
  },
};
</script>

<style lang="scss">
.static-loading {
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.45);
  pointer-events: none;
}

.loader {
  border: 8px solid #fff; /* Light grey */
  border-top: 8px solid #f06445; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.v-facebook-login {
  background: transparent;
  border: none;
  color: #e0c097;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    overflow: visible;
  }

  @media (max-width: 400px) {
    justify-content: center;
    width: 100%;
  }

  .loader {
    width: 15px;
    height: 15px;
    border-width: 5px;
    margin-right: 10px;
  }
}
</style>

<style lang="scss" scoped>
.signin__box {
  margin: 0 auto;
  padding: 63px 20px;
  box-sizing: border-box;
  width: 100%;
  max-width: 500px;
  @media (max-width: 768px) {
    padding: 30px 20px;
  }
  .row {
    .column {
      width: 50%;
      display: inline-block;
      margin-top: 30px;
      @media (max-width: 400px) {
        width: 100%;
      }
      button {
        @media (max-width: 400px) {
          justify-content: center !important;
        }
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
      .googleLogin {
        background: transparent;
        border: none;
        margin-top: 30px;
        color: #e0c097;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0px;
        margin: 0px;
        box-shadow: none;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
  .register {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $white;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    margin-bottom: 120px;
    @media (max-width: 768px) {
      margin-bottom: 60px;
    }
    .link {
      margin-left: 10px;
      text-decoration: none;
      color: $calico;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }
  }
  h3 {
    font-size: 36px;
    font-weight: 800;
    line-height: 50px;
    color: $white;
    margin-bottom: 60px;
    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 42px;
      margin-bottom: 30px;
    }
  }
  .buttons {
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
      justify-content: space-between;
      @media (max-width: 400px) {
        flex-direction: column;
      }
    }
  }
  button {
    cursor: pointer;
    margin-right: 90px;
    background-color: $calico;
    padding: 24px 80px;
    box-shadow: 0px 5px 15px #2d2424;
    border-radius: 16px;
    border: none;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    padding: 20px 25px;
    min-width: 220px;
    min-height: 70px;
    @media (max-width: 1024px) {
      margin-right: 30px;
      padding: 15px 40px;
      @media (max-width: 400px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
      }
    }
    .link {
      text-decoration: none;
      color: $primary-color;
      font-family: Inter;
      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
    }
  }
  .link {
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: $calico;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    transition: 0.3s;
    &:hover {
      color: $white;
    }
  }
}
</style>
