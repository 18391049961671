<template>
  <div>
    <Forgotpassword />
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
import Forgotpassword from "@/components/auth/Forgotpassword.vue";
export default {
  components: {
    Forgotpassword,
  },
  beforeCreate() {
    useHead({
      title: `Cupon | ${this.$t("meta.password_reset")}`,
    });
  },
};
</script>

<style lang="scss" scoped></style>
