<template>
  <div>
    <login />
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
import login from "@/components/auth/Login.vue";
export default {
  components: {
    login,
  },
  beforeCreate() {
    useHead({
      title: `Cupon | ${this.$t("meta.login")}`,
    });
  },
};
</script>

<style lang="scss" scoped></style>
