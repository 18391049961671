<template>
  <div class="container">
    <div class="container__inner">
      <div class="container__inner-left">
        <img
          class="login"
          src="@/assets/img/login.png"
          alt="login-background"
        />
        <div class="description-box">
          <img class="logo" src="@/assets/img/logo.png" alt="logo" />
          <h3 class="title">{{ $t("labels.auth_title") }}</h3>
        </div>
      </div>
      <div class="container__inner-right">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
  background: linear-gradient(
    180deg,
    rgba(94, 61, 48, 1) 0%,
    rgba(78, 53, 44, 1) 35%,
    rgba(45, 36, 36, 1) 100%
  );
  &__inner {
    min-height: 100vh;
    display: flex;
    &-left {
      display: none;
      width: 50%;
      position: relative;
      max-height: 100vh;
      @media only screen and (min-width: 768px) {
        display: block;
      }
      .login {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .description-box {
        position: absolute;
        left: 60px;
        right: 60px;
        bottom: 206px;
        .logo {
          display: block;
          width: 100%;
          max-width: 290px;
        }
        .title {
          margin-top: 40px;
          font-size: 36px;
          font-weight: 600;
          line-height: 43px;
          color: $white;
          font-family: "Rubik", sans-serif;
        }
      }
    }
    &-right {
      width: 50%;
      @media only screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
</style>
