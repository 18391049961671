<template>
  <div class="main-container">
    <div class="main-container__left">
      <Sidebar />
    </div>
    <div class="main-container__right">
      <slot />
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/layout/Sidebar.vue";
export default {
  components: {
    Sidebar,
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  display: grid;
  grid-template-columns: 260px 11fr;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: 60px 11fr;
  }
  height: 100vh;
  overflow: hidden;
  &__right {
    height: 100vh;
    overflow: auto;
  }
}
</style>
