import { importGlobalComponents, getGlobalProperties } from "./libs/appHelper";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n";
import messages from "@/locales/multiLang.json";

import "@/assets/style/style.scss";
import "@vueform/multiselect/themes/default.css";
import "vue2-datepicker/index.css";
import VueFeather from "vue-feather";
import axios from "axios";
import VueAxios from "vue-axios";
import DefaultLayout from "@/layouts/Default";
import AuthLayout from "@/layouts/Auth";
import VueApexCharts from "vue3-apexcharts";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import vClickOutside from "click-outside-vue3";
import { createHead } from "@vueuse/head";
import VueHtmlToPaper from "./plugins/VueHtmlToPaper";

const printOptions = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=no"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    `${process.env.VUE_APP_URL}/print.css`,
  ],
};
import GAuth from "vue3-google-oauth2";

const i18n = createI18n({
  locale: window.localStorage.getItem("locale") || "hr", // set locale
  fallbackLocale: "hr", // set fallback locale
  messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
});

const app = createApp(App);
const head = createHead();

app.use(head);

app.use(i18n);
app.use(VueAxios, axios);
app.use(VueApexCharts);

app.component(VueFeather.name, VueFeather);

app.component("default-layout", DefaultLayout);
app.component("auth-layout", AuthLayout);

app.use(VueHtmlToPaper, printOptions);
const gAuthOptions = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: "email",
  prompt: "consent",
  fetch_basic_profile: false,
};
app.use(GAuth, gAuthOptions);

app.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBwPxuvL8u9-TG7xvIMh6ECwvYcmeetsNM",
    libraries: "places",
    autobindAllEvents: true,
  },
});

app.use(vClickOutside);

importGlobalComponents(["global"]).forEach((c) => {
  app.component(c.name, c.path);
});

getGlobalProperties().forEach((p) => {
  app.config.globalProperties[p.key] = p.value;
});

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.headers.common["Accept"] = "application/json";
axios.interceptors.request.use(
  function (config) {
    config.headers.common["Authorization"] =
      "Bearer " + window.localStorage.getItem("token");

    config.headers.common["Access-Control-Allow-Origin"] =
      process.env.VUE_APP_API_URL;

    config.headers.common["Locale-Code"] =
      window.localStorage.getItem("locale") || "hr";

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Config file global import.
/* app.config.globalProperties.$config = config; */

app.use(store).use(router).mount("#app");

<script src="https://apis.google.com/js/platform.js" async defer></script>;
