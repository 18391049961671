<template>
  <div>
    <Signup />
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
import Signup from "@/components/auth/Signup.vue";
export default {
  components: {
    Signup,
  },
  beforeCreate() {
    useHead({
      title: `Cupon | ${this.$t("meta.register")}`,
    });
  },
};
</script>

<style lang="scss" scoped></style>
