<template>
  <nav>
    <div class="top-part">
      <router-link to="/">
        <img class="logo" src="/logo.svg" alt="Cupon" />
        <svg
          class="logo-mobile"
          width="39"
          height="24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.799 17.613a5.809 5.809 0 0 1-3.197-.863 4.958 4.958 0 0 1-1.575-1.57 5.781 5.781 0 0 1-.865-3.197 5.94 5.94 0 0 1 .716-2.975 4.957 4.957 0 0 1 1.968-1.964 5.779 5.779 0 0 1 2.535-.703h.21c.889.037 1.758.278 2.54.703.506.284.96.652 1.343 1.089l5.994-3.452-.202-.244-.131-.155-.114-.137-.101-.119-.054-.06a10.962 10.962 0 0 0-3.943-2.868A13.365 13.365 0 0 0 12.554.051h-.143a13.341 13.341 0 0 0-5.369 1.047 10.945 10.945 0 0 0-3.93 2.887l-.078.095a.916.916 0 0 0-.102.113l-.083.095c-.084.095-.161.196-.239.292l-.31.369a8.512 8.512 0 0 0-.596.91 12.316 12.316 0 0 0 0 12.26 11.478 11.478 0 0 0 4.51 4.256 13.22 13.22 0 0 0 6.31 1.517h.28a1.308 1.308 0 0 0 1.277-1.303v-3.702a1.271 1.271 0 0 0-1.282-1.274Z"
            fill="#B85C38"
          />
          <path
            d="m38.336.747-6.46 3.773v8.553c0 1.654-.317 2.85-.931 3.57a3.34 3.34 0 0 1-2.672 1c-1.748 0-3.538-.541-3.538-4.54v-1.44a10.062 10.062 0 0 0-.25-2.774l-3.973 2.285-2.852 1.678v.417c0 3.357.949 6 2.822 7.85 1.873 1.851 4.48 2.797 7.755 2.797 3.275 0 5.887-.94 7.755-2.797 1.867-1.857 2.821-4.493 2.821-7.85V1.759a1.306 1.306 0 0 0-.477-1.012Z"
            fill="#B85C38"
          />
        </svg>
      </router-link>

      <ul class="desktop-nav">
        <li>
          <router-link to="/"
            ><svg
              width="16"
              height="16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.199 3.2h-2.4v-.8a2.4 2.4 0 0 0-.952-1.919A2.4 2.4 0 0 0 9.703.105L.544 3.201a.944.944 0 0 0-.152.088.568.568 0 0 0-.112.08.704.704 0 0 0-.096.12.72.72 0 0 0-.088.128.752.752 0 0 0 0 .128A.624.624 0 0 0 0 4v11.2A.8.8 0 0 0 .8 16h14.399a.8.8 0 0 0 .8-.8V4a.8.8 0 0 0-.8-.8Zm-5.024-1.599a.8.8 0 0 1 1.024.8v.8H5.6l4.576-1.6Z"
                fill="#E0C097"
              />
            </svg>
            {{ $t("sidebar.wallet") }}</router-link
          >
        </li>
        <li>
          <router-link to="/coupons"
            ><svg
              width="16"
              height="14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.818 13.09H2.182C.982 13.09 0 12.11 0 10.91V9.393c0-.4.327-.727.727-.727.8 0 1.455-1.321 1.455-2.122 0-.8-.655-2.12-1.455-2.12A.73.73 0 0 1 0 3.696V2.182C0 .982.982 0 2.182 0h11.636C15.018 0 16 .982 16 2.182v1.515a.73.73 0 0 1-.727.727c-.8 0-1.455 1.321-1.455 2.121s.655 2.122 1.455 2.122c.4 0 .727.327.727.727v1.515c0 1.2-.982 2.182-2.182 2.182Z"
                fill="#E0C097"
              /></svg
            >{{ $t("sidebar.coupons") }}</router-link
          >
        </li>
        <li>
          <router-link to="/shops"
            ><svg
              width="15"
              height="16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m14.663 14.27-.679-8.786a1.595 1.595 0 0 0-.527-1.061 1.714 1.714 0 0 0-1.135-.437h-.879c-.704-5.318-7.537-5.312-8.241 0h-.857a1.714 1.714 0 0 0-1.134.436 1.595 1.595 0 0 0-.528 1.06l-.68 8.785c-.015.22.017.442.093.65.076.209.196.4.352.562.155.162.344.292.553.382.21.09.436.137.666.139h11.325c.23-.002.457-.049.667-.138.21-.089.4-.218.555-.38a1.572 1.572 0 0 0 .449-1.212ZM7.333 1.05c1.52 0 2.772 1.279 3.004 2.936H4.32C4.546 2.33 5.804 1.05 7.334 1.05Z"
                fill="#E0C097"
              /></svg
            >{{ $t("sidebar.shops") }}</router-link
          >
        </li>
      </ul>
    </div>

    <div class="bottom-part">
      <div class="flex__box">
        <div class="locale-picker">
          <span
            v-for="(locale, index) in $i18n.availableLocales"
            :key="`locale_${index}`"
            @click="() => changeLocale(locale)"
            :class="{ active: locale === $i18n.locale }"
          >
            {{ locale }}
          </span>
        </div>
        <div class="flex__box-inner">
          <img
            :src="
              authUser && authUser.avatar
                ? authUser.avatar
                : '/avatar_fallback.jpg'
            "
            alt="Avatar"
          />

          <p>
            {{
              authUser.first_name && authUser.last_name
                ? `${authUser.first_name} ${authUser.last_name}`
                : authUser.email
            }}
          </p>
        </div>
        <a class="link logout-link" @click="handleLogout">
          {{ $t("sidebar.logout") }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-log-out"
          >
            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
            <polyline points="16 17 21 12 16 7"></polyline>
            <line x1="21" y1="12" x2="9" y2="12"></line>
          </svg>
        </a>
      </div>
    </div>

    <ul class="mobile-nav">
      <li>
        <router-link to="/"
          ><svg
            width="16"
            height="16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.199 3.2h-2.4v-.8a2.4 2.4 0 0 0-.952-1.919A2.4 2.4 0 0 0 9.703.105L.544 3.201a.944.944 0 0 0-.152.088.568.568 0 0 0-.112.08.704.704 0 0 0-.096.12.72.72 0 0 0-.088.128.752.752 0 0 0 0 .128A.624.624 0 0 0 0 4v11.2A.8.8 0 0 0 .8 16h14.399a.8.8 0 0 0 .8-.8V4a.8.8 0 0 0-.8-.8Zm-5.024-1.599a.8.8 0 0 1 1.024.8v.8H5.6l4.576-1.6Z"
              fill="#E0C097"
            /></svg
          ><span>{{ $t("sidebar.wallet") }}</span></router-link
        >

        <router-link to="/coupons"
          ><svg
            width="16"
            height="14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.818 13.09H2.182C.982 13.09 0 12.11 0 10.91V9.393c0-.4.327-.727.727-.727.8 0 1.455-1.321 1.455-2.122 0-.8-.655-2.12-1.455-2.12A.73.73 0 0 1 0 3.696V2.182C0 .982.982 0 2.182 0h11.636C15.018 0 16 .982 16 2.182v1.515a.73.73 0 0 1-.727.727c-.8 0-1.455 1.321-1.455 2.121s.655 2.122 1.455 2.122c.4 0 .727.327.727.727v1.515c0 1.2-.982 2.182-2.182 2.182Z"
              fill="#E0C097"
            /></svg
          ><span>{{ $t("sidebar.coupons") }}</span></router-link
        >

        <router-link to="/shops"
          ><svg
            width="15"
            height="16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m14.663 14.27-.679-8.786a1.595 1.595 0 0 0-.527-1.061 1.714 1.714 0 0 0-1.135-.437h-.879c-.704-5.318-7.537-5.312-8.241 0h-.857a1.714 1.714 0 0 0-1.134.436 1.595 1.595 0 0 0-.528 1.06l-.68 8.785c-.015.22.017.442.093.65.076.209.196.4.352.562.155.162.344.292.553.382.21.09.436.137.666.139h11.325c.23-.002.457-.049.667-.138.21-.089.4-.218.555-.38a1.572 1.572 0 0 0 .449-1.212ZM7.333 1.05c1.52 0 2.772 1.279 3.004 2.936H4.32C4.546 2.33 5.804 1.05 7.334 1.05Z"
              fill="#E0C097"
            /></svg
          ><span>{{ $t("sidebar.shops") }}</span></router-link
        >
      </li>

      <li class="logout">
        <img
          :src="
            authUser && authUser.avatar
              ? authUser.avatar
              : '/avatar_fallback.jpg'
          "
          alt="Avatar"
        />
        <a @click="handleLogout">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#e0c097"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-log-out"
          >
            <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
            <polyline points="16 17 21 12 16 7"></polyline>
            <line x1="21" y1="12" x2="9" y2="12"></line></svg
          ><span>{{ $t("sidebar.logout") }}</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      authUser: "authUser",
    }),
  },

  methods: {
    handleLogout() {
      this.$auth.handleLogout().then(() => {
        this.$store.dispatch("setAuthUser", {});
        this.$router.push("/log-in");
      });
    },

    changeLocale(locale) {
      this.$i18n.locale = locale;

      if (window.localStorage.getItem("locale")) {
        window.localStorage.removeItem("locale");
      }

      window.localStorage.setItem("locale", locale);
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  background: linear-gradient(18.44deg, #2d2424 78.01%, #5c3d2e 88.52%);
  display: flex;
  flex-wrap: wrap;
  height: 100vh;

  @media screen and (min-width: 1025px) {
    overflow-y: auto;
  }

  .bottom-part {
    margin-top: auto;
    padding-bottom: 20px;
    .flex__box {
      padding: 50px 28px 0 58px;
    }

    .locale-picker {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin-bottom: 50px;

      span {
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        color: rgba(#fff, 0.65);
        text-transform: uppercase;
        cursor: pointer;
        transition: 0.275s;

        &:hover {
          color: rgba(#fff, 1);
        }

        &.active {
          color: #e0c097;
          font-weight: 700;
        }

        &:last-child {
          margin-right: 10px;
          padding-right: 10px;
          border-right: 1px solid rgba(255, 255, 255, 0.35);
        }
      }
    }
  }

  .top-part {
    @media screen and (max-width: 1024px) {
      max-height: 100px;
    }
  }

  .logo {
    margin: 50px 40px 0 40px;
    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }
  .logo-mobile {
    display: none;
    padding: 30px 10px 0 10px;
    @media only screen and (max-width: 1024px) {
      display: block;
    }
  }
  .desktop-nav {
    list-style: none;
    padding: 0;
    margin: 60px 0 0 0;
    @media only screen and (max-width: 1024px) {
      display: none;
    }
    li {
      &:not(:first-child) {
        margin-top: 9px;
      }
      a {
        position: relative;
        margin-inline: 30px;
        text-decoration: none;
        display: flex;
        align-items: center;
        padding: 17px 30px;
        color: $white;
        border-radius: 12px;
        transition: 0.3s;
        font-family: "Rubik", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        &.router-link-active {
          background-color: $cioccolato500;
          &:before {
            background-color: $calico;
          }
          svg path {
            fill: $white;
          }
        }
        &:hover {
          background-color: $cioccolato500;
          &:before {
            background-color: $calico;
          }
          svg path {
            fill: $white;
          }
        }
        &:before {
          content: "";
          display: block;
          width: 4px;
          height: 100%;
          background-color: transparent;
          border-radius: 0px 12px 12px 0px;
          position: absolute;
          left: -30px;
          top: 0;
          bottom: 0;
          transition: 0.3s;
        }
        svg {
          margin-right: 23px;
          path {
            transition: 0.3s;
          }
        }
      }
    }
  }
  .mobile-nav {
    display: none;
    list-style: none;
    padding: 0;
    // margin: 70px 0 0 0;
    margin: none;
    @media only screen and (max-width: 1024px) {
      display: block;
    }
    li {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      &.logout {
        margin-top: 80px;
        img {
          margin: 15px 18px;
          display: block;
          width: 100%;
          max-width: 24px;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      a {
        cursor: pointer;
        box-sizing: border-box;
        position: relative;
        margin-inline: 10px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0px;
        min-height: 40px;
        border-radius: 12px;
        transition: 0.3s;
        span {
          pointer-events: none;
          opacity: 0;
          position: absolute;
          top: 50%;
          padding: 5px 10px;
          border-radius: 8px;
          color: #ffffff;
          transform: translate(0, -50%);
          left: calc(100% + 20px);
          background-color: $cioccolato500;
          font-size: 14px;
          font-family: Rubik;
          transition: 0.3s;
          z-index: 1;
        }
        &.router-link-active {
          background-color: $cioccolato500;
          &:before {
            background-color: $calico;
          }
          svg path {
            fill: $white;
          }
        }
        &:hover {
          background-color: $cioccolato500;
          span {
            opacity: 1;
          }
          &:before {
            background-color: $calico;
          }
          svg path {
            fill: $white;
          }
        }
        &:before {
          content: "";
          display: block;
          width: 4px;
          height: 100%;
          background-color: transparent;
          border-radius: 0px 12px 12px 0px;
          position: absolute;
          left: -10px;
          top: 0;
          bottom: 0;
          transition: 0.3s;
        }
        svg {
          path {
            transition: 0.3s;
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      margin: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      height: calc(100vh - 100px);
      margin-top: 50px;

      li a {
        margin-bottom: 8px;
      }

      li:first-child {
        width: 100%;
      }

      li.logout {
        margin-top: auto;
        margin-bottom: 5px;
      }
    }
  }
  .flex__box {
    box-sizing: border-box;
    padding: 130px 28px 0 58px;
    width: 100%;
    @media only screen and (max-width: 1024px) {
      display: none;
    }
    &-inner {
      display: flex;
      align-items: center;
      margin-bottom: 22px;
      img {
        margin-right: 12px;
        display: block;
        width: 30px;
        border-radius: 50%;
      }
      .avatar {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
        background-size: cover;
        background-position: center;
      }
      p {
        font-family: "Rubik", sans-serif;
        font-weight: 400;
        color: $calico;
        width: calc(100% - 60px);
        line-height: 1.15;
        font-size: 15px;
        display: block;
        max-width: 114px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .link {
      cursor: pointer;
      text-decoration: none;
      font-family: "Rubik", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 18.96px;
      color: $calico;
      transition: 0.3s;
      &:hover {
        color: $white;
      }

      &.logout-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 100%;
        margin-top: 30px;

        svg {
          width: 20px;
          height: 20px;
          margin-top: -2px;
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
