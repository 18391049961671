<template>
  <div
    class="shops"
    :class="{ 'shops--opened': opened }"
    v-click-outside="hide"
    v-if="initied"
  >
    <div class="shops__search" @click="toggle">
      <input v-model="search_input" type="text" :placeholder="ph" />

      <span
        v-if="filters.length > 0"
        class="clear multiselect-clear-icon"
        @click="clear"
      />

      <span class="caret multiselect-caret" />
    </div>

    <div
      v-show="opened"
      class="shops__dropdown"
      :class="{ 'is-processing': processing }"
      @scroll="onScroll"
    >
      <span v-if="itemsFiltered.length === 0" class="no-records">
        No records...
      </span>

      <template v-else>
        <a
          v-for="item in itemsFiltered"
          :key="item.id"
          class="option"
          :class="{
            'option--active': filters.includes(item.id),
          }"
          @click="setFilter(item.id)"
        >
          <img
            v-if="imageCont"
            :src="imageUrl(item, imageCont)"
            class="avatar"
          />
          <span>{{ item.name }}</span></a
        >
      </template>

      <div v-if="processing" class="processing">
        <div class="loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
  emits: ["filterChange"],

  props: {
    fetchMethod: {
      type: String,
      required: true,
    },

    placeholder: {
      type: String,
      required: true,
    },

    imageCont: {
      type: [String, null],
      required: false,
      default: null,
    },
  },

  data: () => ({
    initied: false,
    opened: false,
    processing: false,
    server_total: 0,
    fetch_config: {
      page: 1,
      per_page: 8,
    },
    items: [],
    filters: [],
    search_input: "",
  }),

  computed: {
    ph() {
      // Show filter names.
      if (this.filters.length > 0) {
        const names =
          this.items
            .filter((item) => this.filters.includes(item.id))
            .map((item) => item.name) || [];

        return names.length > 0 ? names.join(", ") : this.placeholder;
      }

      // Show writeable.
      if (this.filters.length === 0 && this.opened === true) {
        return this.placeholder;
      }

      return this.placeholder || "";
    },

    itemsFiltered() {
      return this.items.filter((item) => item.name !== "All");
    },
  },

  mounted() {
    this.fetch({}, false);
  },

  created() {
    this.debouncedWatch = debounce((search) => {
      this.fetch({ search }, false);
    }, 250);
  },

  beforeUnmount() {
    this.debouncedWatch.cancel();
  },

  methods: {
    imageUrl(obj, path) {
      return path.split(".").reduce((value, el) => value[el], obj);
    },

    hide() {
      this.opened = false;
      this.search_input = "";
    },

    toggle() {
      this.opened = !this.opened;

      if (!this.opened) {
        this.search_input = "";
      }
    },

    clear() {
      this.filters = [];
      this.$emit("filterChange", []);
    },

    setFilter(id) {
      if (this.filters.includes(id)) {
        this.filters.splice(this.filters.indexOf(id), 1);
      } else {
        this.filters.push(id);
      }

      this.$emit("filterChange", this.filters);
    },

    fetch(p, appendable) {
      this.processing = true;

      let params = { ...this.fetch_config };

      if (p) {
        params = {
          ...params,
          ...p,
        };
      }

      this.$store
        .dispatch(this.fetchMethod, { params })
        .then((response) => {
          this.server_total = response.data.meta.total;

          if (appendable) {
            response.data.data.forEach((item) => {
              const removeIndex = this.items.findIndex((i) => i.id === item.id);

              if (removeIndex > -1) {
                this.items.splice(removeIndex, 1);
              }

              this.items.push(item);
            });
          } else {
            this.items = response.data.data;
          }
        })
        .catch((error) => console.log("ER", error))
        .finally(() => {
          this.initied = true;
          this.processing = false;
        });
    },

    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (
        scrollTop + clientHeight >= scrollHeight &&
        this.server_total > this.items.length
      ) {
        const fetchConfig = {
          ...this.fetch_config,
          page: this.fetch_config.page + 1,
        };

        this.fetch(fetchConfig, true);
      }
    },
  },

  watch: {
    search_input(...args) {
      this.opened = true;
      this.debouncedWatch(...args);
    },
  },
};
</script>

<style lang="scss" scoped>
.shops {
  min-width: 220px;
  position: relative;

  @media only screen and (max-width: 500px) {
    min-width: 180px;
  }

  &--opened {
    .shops__search {
      border-radius: 8px 8px 0 0;
    }
    .caret {
      transform: rotate(180px);
    }
  }
  &__search {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid $calico;
    border-radius: 8px;
    padding: 8px 14px;
    input {
      cursor: pointer;
      padding: 0;
      width: 100%;
      border: none;
      background: transparent;
      outline: none;
      font-size: 14px;
      line-height: 19px;
      font-family: Rubik;
      &::placeholder {
        color: $wood_bark;
        font-family: Rubik;
      }
    }
    .clear {
      position: absolute;
      right: 32px;
    }
    .caret {
      margin-right: 0;
      position: absolute;
      right: 14px;
    }
  }
  &__dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    background: $white;
    z-index: 2;
    border: 1px solid $calico;
    border-radius: 0 0 8px 8px;
    max-height: 162px;
    overflow: auto;
    border-top: none;
    .option {
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 8px 12px;
      font-size: 12px;
      font-family: Rubik;
      &--active {
        background-color: $calico;
      }
      img {
        margin-right: 10px;
        display: block;
        width: 25px;
      }
    }
  }
}

.shops__search input {
  max-width: 100% !important;
  padding-right: 36px;
  text-overflow: ellipsis;

  ::placeholder {
    text-overflow: ellipsis;
  }
}

.no-records {
  display: block;
  font-size: 12px;
  font-weight: 600;
  opacity: 0.6;
  padding: 10px 15px;
}

.processing {
  padding: 0 0 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
}

/*.is-processing {
  overflow-y: hidden;
}*/

.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #b85c38; /* Blue */
  border-radius: 50%;
  width: 10px;
  height: 10px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
