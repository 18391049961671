<template>
  <div>
    <slot name="header" />
    <div class="table-overflow">
      <table class="table" cellspacing="0" cellpadding="0">
        <thead>
          <tr>
            <th
              v-for="{
                key,
                title,
                isSortable,
                minWidth,
                maxWidth,
                width,
              } in columns"
              :key="key"
              :style="{
                minWidth: minWidth,
                maxWidth: maxWidth,
                width: width,
              }"
            >
              {{ title }}
              <button v-if="isSortable" @click="handleSort(key)">
                {{ $t("labels.sort") }}
              </button>
            </th>
          </tr>
        </thead>
        <tbody id="transition-group" name="scale">
          <template v-if="data && data.length > 0">
            <tr
              v-for="(item, index) in data"
              :key="item[identifier] ? item[identifier] : index"
              class="table__row"
              :class="{ 'table__row--clickable': isClickable }"
              @click="redirectTo(item)"
            >
              <td
                v-for="{ key, customClass } in columns"
                :key="key"
                :class="[customClass]"
              >
                <slot :name="key" :item="item" :colkey="key">
                  {{ item[key] }}
                </slot>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr class="no-data" key="no-data">
              <td :colspan="columns.length">
                <div class="no-data">
                  <img src="@/assets/img/no-result.png" alt="NoResult" />
                  <h5>{{ $t("labels.noResults") }}</h5>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Table",
  props: {
    identifier: {
      type: String,
      required: false,
      default: "",
    },
    columns: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    isClickable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    handleSort(col) {
      this.$emit("sortChanged", col);
    },
    redirectTo(item) {
      if (this.isClickable) {
        this.$emit("redirect", item.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-overflow {
  overflow-x: auto;
  overflow-y: hidden;
}
.table__row--clickable {
  cursor: pointer;
}
.table {
  width: 100%;
  min-width: 800px;
  thead {
    tr {
      th {
        text-align: left;
        padding: 15px 0;
        border-bottom: 1px solid rgba(224, 192, 151, 0.5);
        font-size: 12px;
        line-height: 14px;
        font-weight: 400;
        color: $wood_bark;
        font-family: Rubik;
        &:nth-last-child(-n + 3) {
          text-align: right;
        }
      }
    }
  }
  tbody {
    tr {
      &.no-data {
        td {
          border: none;
        }
      }
      td {
        text-align: left;
        padding: 20px 0;
        border-bottom: 1px solid rgba(224, 192, 151, 0.5);

        &:nth-last-child(-n + 3) {
          text-align: right;
        }
        ::v-deep img {
          margin-right: 14px;
          display: block;
          width: 100%;
          max-width: 30px;
        }
        &:last-child {
          text-align: center;
          width: 70px;
        }
      }
      .no-data {
        display: flex;
        justify-content: center;
        flex-direction: column;
        img {
          margin: 0 auto 30px auto;
          width: 100%;
          max-width: 500px;
        }
      }
    }
  }
}
</style>
