<template>
  <div class="form-group">
    <label v-if="label" :for="id" class="form-label">
      {{ label }}
    </label>

    <input
      class="form-control"
      :class="{ 'is-invalid border-danger': errorMsg }"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :value="modelValue"
      spellcheck="false"
      @input="$emit('update:modelValue', $event.target.value)"
    />

    <small v-if="errorMsg" class="text-danger">
      {{ errorMsg }}
    </small>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import * as rules from "@vuelidate/validators";
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    modelValue: {
      type: [String, null],
      required: true,
    },

    label: {
      type: String,
      required: false,
      default: null,
    },

    name: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      required: false,
      default: "text",
    },

    placeholder: {
      type: String,
      required: false,
      default: null,
    },

    rules: {
      type: [Array, null],
      required: false,
      default: null,
    },

    error: {
      type: [String, null],
      required: false,
      default: null,
    },
  },

  data: () => ({
    v$: useValidate(),
    id: uuidv4(),
  }),

  computed: {
    errorMsg() {
      // Server error.
      if (this.error) {
        return this.error;
      }

      // Input error.
      if (
        this.v$.$errors &&
        this.v$.$errors.length > 0 &&
        this.v$.$errors[0].$validator
      ) {
        return this.$t(`validation.${this.v$.$errors[0].$validator}`);
      }

      return null;
    },
  },

  validations() {
    let rulesObj = {};
    if (!this.rules || this.rules.length === 0) {
      // No rules present.
      rulesObj = {};
    } else {
      // Rules are present.
      this.rules.forEach((r) => {
        rulesObj[r] = rules[r];
      });
    }

    return {
      modelValue: rulesObj,
    };
  },
};
</script>

<style lang="scss" scoped>
.form-group {
  position: relative;
  margin-bottom: 30px;
  .form-label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 15px;
    color: $white;
  }
  .form-control {
    width: 100%;
    box-sizing: border-box;
    padding: 17px 30px;
    font-size: 20px;
    font-weight: 500;
    line-height: 34px;
    background: $white;
    border-radius: 16px;
    border: 1px solid $gray100;
    outline: none;
    &::placeholder {
      color: $primary-color;
      opacity: 0.7;
    }
    @media (max-width: 768px) {
      padding: 8px 15px;
      font-size: 16px;
    }
  }
  .text-danger {
    position: absolute;
    color: $red;
    left: 0;
    bottom: -20px;
  }
}
</style>
